<template>
  <div class="mentions">
    <div class="container">
      <h2>Mentions légales</h2>

      <h3>Propriétaire et Responsable de publication</h3>
      <p>MUSEES</p>
      <p>
        23 Avenue Guy de Collongue
        <br />69130 Écully
      </p>
      <p>
        <a href="mainto:musees2022lyon@em-lyon.com"
          >musees2022lyon@em-lyon.com</a
        >
      </p>

      <h3>Hébergeur</h3>
      <p><strong>OVH</strong> - 2 rue Kellermann 59100 Roubaix</p>

      <h3>Utilisation d’images</h3>
      <p>
        Tous les éléments graphiques du site appartiennent à l’association,
        conforment à l’article L. 122-4 du Code de la propriété intellectuelle,
        toute utilisation de ces éléments sans autorisation écrite particulière
        et préalable est strictement interdite.
      </p>
      <h3>Données personnelles</h3>
      <p>
        Ce site ne collecte pas d'informations personnelles relatives à
        l’utilisateur. Le site n'utilise pas Google Analytics ou tout autre
        service qui est suseptible d'utiliser des cookies.
      </p>
      <p>
        Ce site n’a pas fait l’objet d’une déclaration de conformité auprès de
        la CNIL.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mentions",
};
</script>

<style lang="scss" scoped>
.mentions {
  border-top: 5px solid $purple;
  h3 {
    margin-top: 30px;
  }
  p {
    margin: 10px 0;
  }
}
@media (min-width: 1024px) {
  .mentions {
    h3 {
      margin-top: 40px;
    }
    p {
      margin: 1rem 0;
    }
  }
}
</style>
